<template>
    <div class="card card-body">
        <div>
            <button class="btn btn-primary g" v-b-modal.create>
                <i class="fa fa-plus"></i>
                انشاء قائمة جديدة
            </button>
            <br><br>
            <a href="https://portal.medu.sa/Horizons/pdf/qayimatalmushkilataltulaabia.pdf" target="_blank">
                يتم اعتماد هذه القائمة لعام 1446 <u><strong><i class="fa fa-file"></i> عرض القائمة</strong></u>
            </a>
            <br><br>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        العنوان
                    </th>
                    <th>
                        الفصول المرسل لها
                    </th>
                    <th>
                        الروابط المرسلة
                    </th>
                    <th>
                        المجابة
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="que in ques" :key="que._id">
                        <td>
                            {{ que.title }}
                        </td>
                        <td>
                            <ul>
                                <li v-for="cl in que.classes" :key="cl">
                                    {{ cl.replace(',', ' - ') }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div v-if="que.links">
                                {{ Object.keys(que.links).length }}
                            </div>
                        </td>
                        <td>
                            {{ que.ends }}
                        </td>
                        <td>
                            {{ que.date }}
                        </td>
                        <td>
                            <b-dropdown dropleft style="width: 100%"
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="secondary"
                                size="sm"
                            >
                                <b-dropdown-item @click="selectedQue = que; ends(que._id, true)" v-b-modal.bulk>
                                    <i class="fa fa-pie-chart"></i>
                                    الاحصائيات الاجمالية
                                </b-dropdown-item>
                                <b-dropdown-item @click="selectedQue = que; tx = []; ends(que._id);" v-b-modal.ends>
                                    <i class="fa fa-check-square"></i>
                                    القوائم المجابة
                                </b-dropdown-item>
                                <b-dropdown-item @click="selectedQue = que; getnotsolved()" v-b-modal.notsolved>
                                    <i class="fa fa-user-times"></i>
                                    من لم يجاوب
                                </b-dropdown-item>
                                <b-dropdown-item @click="selectedQue = que;" v-b-modal.resend>
                                    <i class="fa fa-refresh"></i>
                                    اعادة ارسال لمن لم يجاوب
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteQue(que._id)">
                                    <i class="fa fa-trash"></i>
                                    حذف القائمة
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <b-modal id="notsolved" title="من لم يجاوب" hide-footer>
            <div class="col-12 text-center g">
                <button class="btn btn-primary btn-sm" v-b-modal.resend>
                    <i class="fa fa-refresh"></i>
                    اعادة ارسال لهم
                </button> &nbsp;
                <button class="btn btn-success btn-sm" @click="notsolvedexcel()">
                    <i class="fa fa-download"></i>
                    تصدير
                </button>&nbsp;
                <button class="btn btn-warning btn-sm" @click="printdiv('ddd4')">
                    <i class="fa fa-print"></i>
                    طباعة
                </button>
            </div>
            <div id="ddd4">
                <table class="table table-sm table-striped table-hover">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            رقم الهوية
                        </th>
                        <th>
                            الفصل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="student in notsolved" :key="student.name">
                            <td>
                                {{ student.name }}
                            </td>
                            <td>
                                {{ student.number }}
                            </td>
                            <td>
                                {{ student.classname }} - {{ student.classroom }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="resend" title="اعادة ارسال لمن لم يرسل" hide-footer>
            <div class="row">
                <div class="col-12 g">
                    ارسال عبر:
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="resend('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="resend('wa')">
                        <i class="fa fa-whatsapp"></i>
                        اداة المتصفح
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-primary btn-block"  @click="resend('sms')">
                        <i class="fa fa-mobile"></i>
                        SMS
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="create" title="انشاء قائمة جديدة" hide-footer>
            <div class="form-group">
              <h5 for="">العنوان</h5>
              <input type="text"
                class="form-control" placeholder="اكتب هنا..." v-model="title">
            </div>
            <div class="form-group">
              <h5 for="">الوصف</h5>
              <textarea class="form-control" placeholder="اختياري" rows="3" v-model="description"></textarea>
            </div>
            <div class="form-group">
              <h5 for="">الرسالة التي تصل للطالب</h5>
              <textarea class="form-control" placeholder="اكتب هنا..." rows="5" v-model="message"></textarea>
            </div>
            <h5>الفصول المراد الارسال لها</h5>
            <div class="row">
                <div class="col-12" style="padding-top: 5px;" v-for="c in classes" :key="c.classname+c.classroom">
                    <b-form-checkbox
                        v-model="selected_classes"
                        :value="c.classname+','+c.classroom"
                    >
                        {{ c.classname + ' - ' + c.classroom }}
                    </b-form-checkbox>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 g">
                    ارسال عبر:
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="send('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="send('wa')">
                        <i class="fa fa-whatsapp"></i>
                        اداة المتصفح
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-primary btn-block"  @click="send('sms')">
                        <i class="fa fa-mobile"></i>
                        SMS
                    </button>
                </div>
                <div class="col-12 g">
                    سيتم حفط القائمة في حسابك بمجرد الارسال.
                </div>
            </div>
        </b-modal>
        <b-modal id="ends" title="القوائم المجابة" hide-footer size="lg">
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="excel()">
                    <i class="fa fa-file"></i>
                    تصدير الى اكسل
                </button>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm table-striped">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            عرض
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="e in ends_arr" :key="e.stundet_number">
                            <td>
                                {{ e.student_name }}
                            </td>
                            <td>
                                {{ e.classroom }}
                            </td>
                            <td>
                                <button class="btn btn-primary btn-sm" @click="single(e.data, selectedQue.arr)" v-b-modal.single>
                                    <i class="fa fa-eye"></i>
                                    عرض التفاصيل
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="single" title="التفاصيل" hide-footer size="lg">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>الخيار</th>
                        <th>
                            الاجابة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="s in single_arr" :key="s.title">
                            <td>
                                {{ s.title }}
                            </td>
                            <td>
                                <span :class="s.value.includes('--') ? 'text-muted' : ''">{{ s.value }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="bulk" title="التفاصيل" hide-footer size="lg">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>الخيار</th>
                        <th>
                            الاجمالي
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="s in bulk_arr" :key="s.title">
                            <td style="white-space: normal !important">
                                {{ s.title }}
                            </td>
                            <td>
                                <ul>
                                    <template v-for="(o,t) in s.options">
                                        <li v-if="o || o == 0" :key="t">
                                            {{ t }}: {{ o }} <a href="javascript:;" @click="tx = [s.title, t]; checkends()" v-b-modal.ends><i class="fa fa-eye"></i></a>
                                        </li>
                                    </template>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, VBModal, BDropdown, BDropdownItem, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
const XLSX = require('xlsx');
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
        BFormCheckbox
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            selectedQue: {},
            tx: [],
            oends_arr: [],
            user: JSON.parse(localStorage.getItem('user')),
            title: "",
            description: "",
            message: `الطالب {name}
يرجى الاجابة على قائمة المشكلات التالية
رابط الاجابة: {link}`,
            classes: [],
            selected_classes: [],
            arr: [],
            studentsx: [],
            students: [],
            ques: [],
            ends_arr: [],
            single_arr: [],
            bulk_arr: [],
            notsolved: []
        }
    },
    created(){
        if(!checkPer("mentors")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/list', {
            jwt: g.user.jwt,
            page: 1,
            perpage: 11111
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
        g.getQues()
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push({
                            classname: a.title,
                            classroom: b
                        })
                    })
                })
            }
        }).fail(function(){
        })
        $.post(api + '/general/que-sheet', {
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.arr = r.response
            }
        }).fail(function(){
        })
    },
    methods: {
        checkends(){
            var g = this;
            if(g.tx.length){
                var ar = [];
                g.oends_arr.forEach(function(a){
                    if(JSON?.parse(a?.data)){
                        if(JSON?.parse(a?.data)[g.tx[0]] == g.tx[1]){
                            ar.push(a)
                        }
                    }
                })
                g.ends_arr = ar;
            }
        },
        printdiv(id){
            var x = window.open(``)
            x.document.write(`
            <html dir="rtl">
            <style>
            
            table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}</style>
                <body>
                    ${$("#" + id).html()}
                </body>
            </html>
            `)
            x.document.close()
            x.print()
        },
         notsolvedexcel(){
        const wb = XLSX.utils.book_new(), g = this;
        var x = [
            "الاسم",
            "رقم الهوية",
            "الفصل"
        ];
        const Heading = [
            x
        ];
        var xx = [];
        g.notsolved.forEach(function(a){
            var xr = [];
            xr.push(a.name)
            xr.push(a.number)
            xr.push(a.classname + " - " + a.classroom)
            xx.push(xr)
        })
        
        // creating sheet and adding data from 2nd row of column A.
        // leaving first row to add Heading
        const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
            
        // adding heading to the first row of the created sheet.
        // sheet already have contents from above statement.
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
            
        // appending sheet with a name
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.writeFile(wb, g.selectedQue.title + ".xlsx");
         },
         excel(){
        const wb = XLSX.utils.book_new(), g = this;
        var x = [
            "الاسم",
            "الفصل"
        ];
        g.arr.forEach(function(a){
            x.push(a.title)
        })
        const Heading = [
            x
        ];
        var xx = [];
        g.ends_arr.forEach(function(a){
            var xr = [];
            xr.push(a.student_name)
            xr.push(a.classroom)
            g.arr.forEach(function(q){
                if(JSON.parse(a.data)[q.title]){
                    if(q.type == "checkbox"){
                        xr.push(JSON.parse(a.data)[q.title].join(','))
                    }else{
                        xr.push(JSON.parse(a.data)[q.title])
                    }
                }else{
                    xr.push("لم يتم الاجابة")
                }
            })
            xx.push(xr)
        })
        
        // creating sheet and adding data from 2nd row of column A.
        // leaving first row to add Heading
        const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
            
        // adding heading to the first row of the created sheet.
        // sheet already have contents from above statement.
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
            
        // appending sheet with a name
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.writeFile(wb, g.selectedQue.title + ".xlsx");
        },
        getnotsolved(){
            var g = this;
            $.post(api + '/user/mentors/que-ends', {
                jwt: g.user.jwt,
                id: this.selectedQue._id
                }).then(function(r){
                    var xxx = r.response.map(function(x){
                        return x.student_number;
                    })
                var studentsx = [];
                g.students.forEach(function(a){
                    if(g.selectedQue.classes.includes(a.classname + ',' + a.classroom) && !xxx.includes(a.number)){
                        studentsx.push(a)
                    }
                })
                g.notsolved = studentsx
            })
        },
        resend(method){
            var g = this;
            var messages = [];
            var que = g.selectedQue;
            $.post(api + '/user/mentors/que-ends', {
                jwt: g.user.jwt,
                id: que._id
            }).then(function(r){
                var xxx = r.response.map(function(x){
                    return x.student_number;
                })
            g.studentsx = [];
            g.students.forEach(function(a){
                if(g.selectedQue.classes.includes(a.classname + ',' + a.classroom) && !xxx.includes(a.number)){
                    g.studentsx.push(a)
                }
            })
            g.studentsx.forEach(function(a){
                messages.push({
                    phones: a.phones,
                    message: g.message.replace("{name}", a.name).replace("{link}", `({${window.location.href.split("_mentors/")[0]}que/${que.links[a.number]}})`)
                })
            })
            $.post(api + '/user/general/short-links', {
                jwt: g.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("mentors")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "mentors"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            g.getQues()
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                alert("حدث خطأ.", 200)
            })
            }).fail(function(){
                alert("حدث خطأ..", 200)
            })
        },
        send(method){
            var g = this;
            var messages = [];
            $.post(api + '/user/mentors/create', {
                jwt: g.user.jwt,
                title: g.title,
                classes: g.selected_classes,
                description: g.description,
                arr: JSON.stringify(g.arr)
            }).then(function(rx){
                if(rx.status != 100){
                    return alert("خطأ " + rx.response, 200)
                }
                var que = rx.response;
                g.studentsx = [];
                g.students.forEach(function(a){
                    if(g.selected_classes.includes(a.classname + ',' + a.classroom)){
                        g.studentsx.push(a)
                    }
                })
            g.studentsx.forEach(function(a){
                messages.push({
                    phones: a.phones,
                    message: g.message.replace("{name}", a.name).replace("{link}", `({${window.location.href.split("_mentors/")[0]}que/${que.links[a.number]}})`)
                })
            })
            $.post(api + '/user/general/short-links', {
                jwt: g.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("mentors")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "mentors"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            g.getQues()
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                alert("حدث خطأ.", 200)
            })
            }).fail(function(e){
                console.log(e)
                alert("حدث خطأ..", 200)
            })
        },
        getQues(){
            var g = this;
            $.post(api + '/user/mentors/ques', {
                jwt: g.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.ques = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        deleteQue(id){
            var g = this;
            if(confirm("متأكد من الحذف؟")){
                $.post(api + '/user/mentors/delete-que', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getQues()
                    }
                }).fail(function(){
                    g.loading = false;
                })
            }
        },
        ends(id, bu=false){
            var g = this;
            g.ends_arr = [];
            $.post(api + '/user/mentors/que-ends', {
                jwt: g.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.ends_arr = r.response
                    g.oends_arr = r.response
                    if(bu){
                        g.bulk()
                    }
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        single(student, arr){
            var g = this;
            g.single_arr = [];
            student = JSON.parse(student)
            JSON.parse(arr).forEach(function(a){
                var o = {
                    title: a.title,
                    value: "--لم يتم الاختيار--"
                };
                if(student[a.title]){
                    o.value = student[a.title]
                }
                g.single_arr.push(o)
            })
        },
        bulk(){
            var g = this;
            g.bulk_arr = [];
            JSON.parse(g.selectedQue.arr).forEach(function(a){
                if(a.type == '1'){
                    var options = {};
                    a.options.forEach(function(b){
                        options[b] = 0;
                    })
                }else{
                    var options = {
                        "نعم": 0,
                        "لا": 0,
                        "أحياناً": 0
                    }
                }
                var o = {
                    title: a.title,
                    options: options
                };
                g.ends_arr.forEach(function(c){
                    for (const [key, value] of Object.entries(JSON.parse(c.data))) {
                        if(key == a.title){
                            o.options[value]++
                        }
                    }
                });
                g.bulk_arr.push(o)
            })
        }
    }
}
</script>

<style>

</style>